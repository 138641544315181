.Footer {
    margin-top: auto;
    background-color: black;
    color: white;
    padding: 0px;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 100%;
}